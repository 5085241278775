import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function getGradeList() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.GRADES)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
