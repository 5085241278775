import { COMMON } from './common';

export const REST_URL = COMMON.ROOT_URL;
export const REST_LOGIN_URL = COMMON.LOGIN_URL;

export const REST_API = {
  USER: {
    LOGIN: `${REST_LOGIN_URL}/authenticate`,
    ME: `${REST_LOGIN_URL}/me`,
  },
  MYPAGE: {
    CHANGE_PASSWORD: `${REST_URL}/change-password`,
    GRADES: `${REST_URL}/karate-grades`,
    EVENTS: `${REST_URL}/karate-events`,
    ATTENDANCES: `${REST_URL}/karate-attendances`,
  },
};
