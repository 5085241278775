import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Container, Box, Button,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import {
  EmojiEvents as EmojiEventsIcon,
  ExitToApp as ExitToAppIcon,
  Fingerprint as FingerprintIcon,
  RotateLeft as RotateLeftIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import redirectLinks from '../../../constants/redirectLinks';
import LogoutDialog from '../../../components/template/logoutDialog/LogoutDialog';

const styles = () => ({
  root: {
    display: 'flex',
    width: 'fit-content',
  },
  media: {
    flex: '0 0 auto',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    marginTop: '-30px',
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      isLogoutConfOpen: false,
    };
  }

  onChangePasswordClick= () => {
    this.props.history.push({
      pathname: redirectLinks.CHANGE_PASSWORD,
    });
  }

  handleClickLink = () => {
    const url = 'http://shieijyuku.com/todoke.htm';
    window.open(url, '_blank');
  };

  onLogoutClick = () => {
    this.setState({ isLogoutConfOpen: true });
  }

  onLogoutCancelClick = () => {
    this.setState({ isLogoutConfOpen: false });
  }

  onClickQr = () => {
    this.props.history.push({
      pathname: redirectLinks.QR,
    });
  }

  logout = () => {
    localStorage.clear();
    document.cookie = 'mjwt=; path=/; max-age=0';
    window.location.href = redirectLinks.LOGIN;
  }

  render() {
    const { user } = this.props.loginUser;
    const userName = user ? `${user.user.lastName} ${user.user.firstName}` : '';
    const address = user ? user.address : '';
    const grade = user ? user.grade : '';
    const { isLogoutConfOpen } = this.state;

    return (
      <Container>
        <Box my={5} textAlign="center" bgcolor="#fff" p={2}>
          <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">{userName}</Box>
          <Box>{address}</Box>
          <Box mt={2} fontWeight="fontWeightBold" fontSize="h5.fontSize" display={grade ? 'flex' : 'none'} alignItems="center" justifyContent="center">
            <EmojiEventsIcon style={{ color: orange[500] }} fontSize="large" />
            {grade}
          </Box>
          <Box textAlign="center" mt={3}>
            <Button variant="contained" onClick={this.onClickQr} startIcon={<FingerprintIcon />}>会員QRコードを表示する</Button>
          </Box>
          <Box textAlign="center" mt={3}>
            <Button variant="contained" onClick={this.onChangePasswordClick} startIcon={<RotateLeftIcon />}>パスワード変更</Button>
          </Box>
          <Box textAlign="center" mt={3}>
            <Button variant="contained" onClick={this.handleClickLink} startIcon={<CheckIcon />}>規約など</Button>
          </Box>
          <Box textAlign="center" mt={3}>
            <Button variant="contained" color="primary" onClick={this.onLogoutClick} startIcon={<ExitToAppIcon />}>ログアウトする</Button>
          </Box>
        </Box>
        <LogoutDialog
          onClose={this.onLogoutCancelClick}
          onLogout={this.logout}
          open={isLogoutConfOpen}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default withStyles(styles)(connect(mapStateToProps)(Home));
