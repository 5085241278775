import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Box, Button,
  Paper, Container, Dialog,
  DialogContent, DialogActions,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import Validation from './passwordValidation';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiChangePassword, actResetChangePasswordFlg } from '../../../redux/mypage/changePassword/action';
import Loading from '../../../components/atoms/loading/Loading';
import redirectLinks from '../../../constants/redirectLinks';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
  },
  boxRoot: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  attention: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
});

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      message: {
        password: '',
        passwordConfirm: '',
      },
      isButtonDisabled: true,
      isPasswordCompleteOpen: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changePassword.isPasswordUpdate !== this.props.changePassword.isPasswordUpdate) {
      if (this.props.changePassword.isPasswordUpdate) {
        this.completePassword();
      }
    }
  }

  completePassword = () => {
    this.setState({
      isPasswordCompleteOpen: true, isLoading: false, password: '', passwordConfirm: '',
    });
  }

  closeCompleteDialog = () => {
    this.setState({ isPasswordCompleteOpen: false, isLoading: true });
    document.cookie = 'mjwt=; path=/; max-age=0';
    localStorage.clear();
    window.location.href = redirectLinks.LOGIN;
  }

  onPasswordChange = (event) => {
    const { name, value } = event.target;
    const { passwordConfirm } = this.state;

    let isButtonDisabled = false;
    let passwordConfMessage = '';
    const passwordMessage = Validation.formValidate(name, value);
    if (passwordConfirm) {
      passwordConfMessage = Validation.formValidate('passwordConfirm', passwordConfirm, value);
    }
    if (passwordMessage || passwordConfMessage || !passwordConfirm) {
      isButtonDisabled = true;
    }

    this.setState({
      [name]: value,
      message: {
        [name]: passwordMessage,
        passwordConfirm: passwordConfMessage,
      },
      isButtonDisabled,
    });
  }

  onConfirmPasswordChange = (event) => {
    const { name, value } = event.target;
    const { message, password } = this.state;
    const messages = {
      ...message,
      [name]: Validation.formValidate(name, value, password),
    };
    let isButtonDisabled = false;
    if (messages.password || messages.passwordConfirm) {
      isButtonDisabled = true;
    }
    this.setState(
      {
        isButtonDisabled,
        [name]: value,
        message: messages,
      },
    );
  }

  onClick = () => {
    this.props.dispatch(actResetChangePasswordFlg());
    const { password, passwordConfirm } = this.state;
    const messages = {
      password: Validation.formValidate('password', password),
      passwordConfirm: Validation.formValidate('passwordConfirm', passwordConfirm, password),
    };
    this.setState({ message: messages });
    if (messages.password || messages.passwordConfirm) {
      return;
    }
    this.setState({ isLoading: true });
    this.props.dispatch(actCallApiChangePassword(password));
  }

  render() {
    const { classes } = this.props;
    const {
      password, passwordConfirm, message, isButtonDisabled, isPasswordCompleteOpen, isLoading,
    } = this.state;

    return (
      <Container>
        <Loading open={isLoading} />
        <Paper className={classes.root} variant="outlined">
          <Box>
            <Box mt={2} className={classes.boxRoot}>
              <Alert severity="info">
                <AlertTitle>パスワードのルール</AlertTitle>
                <Box mb={1} className={classes.attention}>・8文字以上、16文字以下で設定してください</Box>
                <Box className={classes.attention}>・半角英数字、記号 (! # $ @ . _) のみで入力出来ます</Box>
              </Alert>
              <Box mt={2}>
                <Alert severity="warning">パスワード変更後自動ログアウトします</Alert>
              </Box>
            </Box>
            <Box className={classes.boxRoot} mt={3}>
              <Box fontWeight="fontWeightBold">新しいパスワード</Box>
              <PasswordField
                value={password}
                name="password"
                onChange={this.onPasswordChange}
                error={message.password !== ''}
              />
              <FormErrorText>{message.password}</FormErrorText>
            </Box>
            <Box className={classes.boxRoot} mt={3}>
              <Box fontWeight="fontWeightBold">新しいパスワードの確認</Box>
              <PasswordField
                value={passwordConfirm}
                name="passwordConfirm"
                onChange={this.onConfirmPasswordChange}
                error={message.passwordConfirm !== ''}
              />
              <FormErrorText>{message.passwordConfirm}</FormErrorText>
            </Box>
            <Box textAlign="center" my={3}>
              <Button variant="contained" color="primary" onClick={this.onClick} disabled={isButtonDisabled}>パスワードを変更する</Button>
            </Box>
          </Box>
        </Paper>
        <Dialog
          open={isPasswordCompleteOpen}
          onClose={this.closeCompleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          disableBackdropClick
          maxWidth="sm"
        >
          <DialogContent dividers>
            パスワードの変更が完了しました
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCompleteDialog}>閉じる</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ changePassword: state.changePassword });

export default withStyles(styles)(connect(mapStateToProps)(ChangePassword));
