import { ACTION_TYPE } from './action';

// #region Reducer
const changePassword = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordUpdate: true,
      };
    case ACTION_TYPE.RESET_CHANGE_PASSWORD_FLG:
      return {
        ...state,
        isPasswordUpdate: false,
      };
    default:
      return state;
  }
};

export default changePassword;
