import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, withStyles,
  CssBaseline, BottomNavigation, BottomNavigationAction,
} from '@material-ui/core';
import {
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  Home as HomeIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
} from '@material-ui/icons';
import redirectLinks from '../../../constants/redirectLinks';

const styles = () => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
  },
});

class SpMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0,
    };
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onChange = (_, value) => {
    this.setState({ value });
    let url = '';
    this.scrollToTop();
    switch (value) {
      case 0:
        url = redirectLinks.HOME;
        break;
      case 1:
        url = redirectLinks.BASE;
        break;
      case 2:
        url = redirectLinks.EVENT;
        break;
      case 3:
        url = redirectLinks.ATTENDANCE;
        break;
      default:
        url = redirectLinks.HOME;
    }
    this.props.history.push({
      pathname: url,
    });
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <>
        <CssBaseline />
        <AppBar>
          <Toolbar>
            <Typography variant="h6">新國際空手拳法道 士衛塾</Typography>
          </Toolbar>
        </AppBar>
        <BottomNavigation
          value={value}
          onChange={this.onChange}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="段位級位" icon={<EmojiEventsIcon />} />
          <BottomNavigationAction label="イベント履歴" icon={<EventIcon />} />
          <BottomNavigationAction label="出席履歴" icon={<TransferWithinAStationIcon />} />
        </BottomNavigation>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(SpMenu));
