import React from 'react';
import {
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  Fingerprint as FingerprintIcon,
  LockOpen as LockOpenIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import redirectLinks from '../../../constants/redirectLinks';

export default function getMenuList() {
  return [
    {
      id: 1,
      name: '段級位',
      icon: (<EmojiEventsIcon />),
      url: redirectLinks.BASE,
    },
    {
      id: 2,
      name: 'イベント履歴',
      icon: (<EventIcon />),
      url: redirectLinks.EVENT,
    },
    {
      id: 3,
      name: '出席履歴',
      icon: (<TransferWithinAStationIcon />),
      url: redirectLinks.ATTENDANCE,
    },
    {
      id: 4,
      name: '会員QR',
      icon: (<FingerprintIcon />),
      url: redirectLinks.QR,
    },
    {
      id: 5,
      name: '規約など',
      icon: (<CheckIcon />),
    },
    {
      id: 6,
      name: 'パスワード変更',
      icon: (<LockOpenIcon />),
      url: redirectLinks.CHANGE_PASSWORD,
    },
  ];
}
