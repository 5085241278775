const redirectLinks = {
  BASE: '/',
  HOME: '/home',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  EVENT: '/events',
  ATTENDANCE: '/attendances',
  QR: '/qr',
  ERROR: '/error',
};
export default redirectLinks;
