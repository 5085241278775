import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function updatePassword(password) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.MYPAGE.CHANGE_PASSWORD, null, password)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
