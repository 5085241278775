export default class Common {
  constructor() {
    this.handleResponse = this.handleResponse.bind(this);
  }

  /**
   * Function use for reformat response
   * @param response
   * @returns {undefined|PromiseLike<T>|Promise<T>|void}
   */
  handleResponse(response) {
    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(new Error('Call api failed'));
      }
    });
  }

  /**
   * Function use for error response check
   *
   * @returns {undefined|PromiseLike<T>|Promise<T>|void}
   */
  handleErrorResponse(error) {
    const { response } = error;
    if (response.status !== 401) {
      window.location.href = '/error';
      return;
    }
    throw error;
  }
}
