import { all } from 'redux-saga/effects';
import watchCallLogin from './login/saga';
import watchCallChangePassword from './mypage/changePassword/saga';
import watchCallGradeList from './mypage/gradeList/saga';
import watchCallEventList from './mypage/eventList/saga';
import watchCallAttendanceList from './mypage/attendanceList/saga';

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* rootSaga() {
  yield all([
    watchCallLogin(),
    watchCallChangePassword(),
    watchCallGradeList(),
    watchCallEventList(),
    watchCallAttendanceList(),
  ]);
}
