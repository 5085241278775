import { call, put, takeLatest } from 'redux-saga/effects';
import getEventList from '../../../services/mypage/eventList.service';
import {
  ACTION_TYPE,
  actCallApiGetEventListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetEventList({ param }) {
  try {
    const datas = yield call(getEventList, param);
    yield put(actCallApiGetEventListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallEventList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_EVENT_LIST, callApiGetEventList);
}
