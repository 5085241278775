import React from 'react';
import connect from 'react-redux/es/connect/connect';
import QRCode from 'qrcode.react';
import {
  withStyles, Container, Box,
} from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    width: 'fit-content',
  },
  media: {
    flex: '0 0 auto',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    marginTop: '-30px',
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

class MemberQr extends React.Component {
  onClick= () => {}

  render() {
    // const { classes } = this.props;
    const { user } = this.props.loginUser;
    return (
      <Container>
        <Box my={5} fontWeight="fontWeightBold" fontSize={15} textAlign="center">{user && (`会員番号：${user.user.code}`)}</Box>
        <Box textAlign="center">
          <QRCode value={user && user.user.code} size={250} />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default withStyles(styles)(connect(mapStateToProps)(MemberQr));
