import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import {
  Box, Drawer, withStyles, Divider,
  AppBar, Toolbar, List, Typography,
  ListItem, ListItemText, ListItemAvatar,
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  EmojiEvents as EmojiEventsIcon,
} from '@material-ui/icons';
import getMenuList from './menu-list';

const drawerWidth = 500;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
});

class MdMenu extends Component {
  constructor() {
    super();
    this.menus = getMenuList();
  }

  handleClick = (_, url) => {
    this.scrollToTop();
    this.props.history.push({
      pathname: url,
    });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  handleClickLink = () => {
    const url = 'http://shieijyuku.com/todoke.htm';
    window.open(url, '_blank');
  };

  render() {
    const { user } = this.props.loginUser;
    const { classes, location, onLogoutClick } = this.props;
    const userName = user ? `${user.user.lastName} ${user.user.firstName}` : '';
    const address = user ? user.address : '';
    const grade = user ? user.grade : '';

    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              新國際空手拳法道 士衛塾
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <Box mt={5} mb={3} textAlign="center">
            <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">{userName}</Box>
            <Box>{address}</Box>
            <Box mt={2} fontWeight="fontWeightBold" fontSize="h5.fontSize" display={grade ? 'flex' : 'none'} alignItems="center" justifyContent="center">
              <EmojiEventsIcon style={{ color: orange[500] }} fontSize="large" />
              {grade}
            </Box>
          </Box>
          <Divider />
          <Box mb={3} />
          {this.menus.map((menu) => (
            <List key={menu.id}>
              {menu.id === 5 && (
                <ListItem
                  button
                  onClick={this.handleClickLink}
                >
                  <ListItemAvatar>{menu.icon}</ListItemAvatar>
                  <ListItemText primary={menu.name} />
                </ListItem>
              )}
              {menu.id !== 5 && (
                <ListItem
                  button
                  selected={menu.url === location.pathname}
                  onClick={(event) => this.handleClick(event, menu.url)}
                >
                  <ListItemAvatar>{menu.icon}</ListItemAvatar>
                  <ListItemText primary={menu.name} />
                </ListItem>
              )}
            </List>
          ))}
          <List>
            <ListItem button onClick={onLogoutClick}>
              <ListItemAvatar><ExitToAppIcon /></ListItemAvatar>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default withStyles(styles)(connect(mapStateToProps)(withRouter(MdMenu)));
