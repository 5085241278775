// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_ATTENDANCE_LIST: 'CALL_API_GET_ATTENDANCE_LIST',
  CALL_API_GET_ATTENDANCE_LIST_SUCCESS: 'CALL_API_GET_ATTENDANCE_LIST_SUCCESS',
};
// #endregion

export const actCallApiGetAttendanceList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_ATTENDANCE_LIST,
  param,
});

export const actCallApiGetAttendanceListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_ATTENDANCE_LIST_SUCCESS,
  datas,
});
