// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_EVENT_LIST: 'CALL_API_GET_EVENT_LIST',
  CALL_API_GET_EVENT_LIST_SUCCESS: 'CALL_API_GET_EVENT_LIST_SUCCESS',
};
// #endregion

export const actCallApiGetEventList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_EVENT_LIST,
  param,
});

export const actCallApiGetEventListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_EVENT_LIST_SUCCESS,
  datas,
});
