import React from 'react';
import {
  Button, Box, makeStyles,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    color: '#f50057',
    fontWeight: '600',
  },
  content: {
    color: '#000',
  },
}));

export default function LogoutDialog(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    onLogout,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      disableBackdropClick
      maxWidth="sm"
    >
      <DialogContent dividers>
        <Box id="alert-dialog-slide-description" className={classes.content}>
          <Box>
            ログアウトします。よろしいですか？
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onLogout} color="secondary">ログアウトする</Button>
      </DialogActions>
    </Dialog>
  );
}
