import { combineReducers } from 'redux';
import common from './common/reducer';
import loginUser from './login/reducer';
import changePassword from './mypage/changePassword/reducer';
import gradeList from './mypage/gradeList/reducer';
import eventList from './mypage/eventList/reducer';
import attendanceList from './mypage/attendanceList/reducer';

const appReducer = combineReducers({
  common,
  loginUser,
  changePassword,
  gradeList,
  eventList,
  attendanceList,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
