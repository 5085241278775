import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Button, TextField, Link, Hidden,
  Grid, Box, Typography, withStyles, Paper,
} from '@material-ui/core';
import { actCallApiLogin, actLoginDataReset } from '../../redux/login/action';
import redirectLinks from '../../constants/redirectLinks';
import logo from '../../public/assets/loginLogo.png';
import shieijyukuLogo from '../../public/assets/shieijyukuyoko.jpg';
import Loading from '../../components/atoms/loading/Loading';
import isNotPc from './login-func';

// #region css
const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    borderBottom: 'solid 2px',
  },
  logo: {
    width: '60%',
    height: 'auto',
  },
  loginLogo: {
    width: '30%',
    height: 'auto',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: '#2196f3',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
// #endregion

class Login extends React.Component {
  constructor(props) {
    super(props);
    document.cookie = 'mjwt=; path=/; max-age=0';
    localStorage.clear();
    props.dispatch(actLoginDataReset());
    this.state = {
      info: {
        email: '',
        password: '',
      },
      message: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUser !== this.props.loginUser) {
      let message = '';
      if (this.props.loginUser.isError === false) {
        this.success(this.props.loginUser.token.token);
      }
      if (this.props.loginUser.isError === true) {
        message = 'メールアドレスまたはパスワードが間違っています';
      }
      this.setStatus(message);
    }
  }

  success = (token) => {
    document.cookie = `mjwt=${token}; path=/; max-age=604800`;
    let url = redirectLinks.BASE;
    if (isNotPc()) {
      url = redirectLinks.HOME;
    }
    this.props.history.push(url);
  }

  setStatus = (message) => {
    const { info } = this.state;
    this.setState({ message, info: { ...info, password: '' }, loading: false });
  }

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.email || !info.password) {
      return;
    }

    this.props.dispatch(actCallApiLogin(info));
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [name]: value } });
  };

  render() {
    const { classes } = this.props;
    const {
      info, message, loading,
    } = this.state;

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Loading open={loading} />
        <Hidden xsDown>
          <Grid item sm={4} md={6}>
            <div className={classes.paper}>
              <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h4.fontSize' }} className={classes.title}>MEMBERSHIP SITE</Box>
              <Box textAlign="center" my={4}>
                <img src={logo} alt="ロゴ" className={classes.logo} />
              </Box>
              <Box textAlign="center">
                <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h4.fontSize' }}>SHIEI-JYUKU</Box>
                <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h4.fontSize' }}>INTERNATIONAL</Box>
                <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h4.fontSize' }}>FEDERATION</Box>
              </Box>
              <Box textAlign="center" mt={4}>
                <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h5.fontSize' }}>FOUNDER</Box>
                <Box fontWeight="fontWeightBold" fontSize={{ sm: 20, md: 'h5.fontSize' }}>SHIHAN ETUZAN KIMURA</Box>
              </Box>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box justifyContent="center" display="flex" alignItems="center" mb={2}>
              <img src={shieijyukuLogo} alt="ロゴ" className={classes.loginLogo} />
            </Box>
            <Typography component="h1" variant="h5">
              ログイン
            </Typography>
            <form className={classes.form} onSubmit={this.handleBtnLogin}>
              <Box textAlign="center" color="#f50057" my={1}>{message}</Box>
              <Box fontWeight="fontWeightBold">メールアドレスまたは会員番号</Box>
              <TextField
                variant="outlined"
                fullWidth
                name="email"
                autoComplete="off"
                autoFocus
                required
                value={info.email}
                onChange={(event) => this.handleChange(event)}
              />
              <Box fontWeight="fontWeightBold" mt={3}>パスワード</Box>
              <TextField
                required
                variant="outlined"
                fullWidth
                name="password"
                type="password"
                autoComplete="off"
                id="password"
                value={info.password}
                onChange={(event) => this.handleChange(event)}
              />
              <Box mt={3}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  Sign In
                </Button>
              </Box>
              <Box mt={5}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {'Copyright © '}
                  <Link color="inherit" href="http://shieijyuku.com/">
                    SHIEI-JYUKU
                  </Link>
                  {' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Login)));
