import React from 'react';
import {
  withStyles, Hidden, CssBaseline,
} from '@material-ui/core';
import MdMenu from './MdMenu';
import SpMenu from './SpMenu';
import LogoutDialog from '../../../components/template/logoutDialog/LogoutDialog';
import redirectLinks from '../../../constants/redirectLinks';
import mainLogo from '../../../public/assets/mainLogo.png';

const drawerWidth = 500;

const styles = (theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      position: 'relative',
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    backgroundImage: `url(${mainLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '65%',
    backgroundAttachment: 'fixed',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      flexGrow: 'none',
      marginTop: theme.spacing(10),
      padding: theme.spacing(0),
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
  },
});

class Base extends React.Component {
  constructor() {
    super();
    this.state = {
      isLogoutConfOpen: false,
    };
  }

  onLogoutClick = () => {
    this.setState({ isLogoutConfOpen: true });
  }

  onLogoutCancelClick = () => {
    this.setState({ isLogoutConfOpen: false });
  }

  logout = () => {
    localStorage.clear();
    document.cookie = 'mjwt=; path=/; max-age=0';
    window.location.href = redirectLinks.LOGIN;
  }

  render() {
    const { children, classes } = this.props;
    const { isLogoutConfOpen } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Hidden mdDown>
          <MdMenu
            onLogoutClick={this.onLogoutClick}
          />
        </Hidden>
        <Hidden lgUp>
          <SpMenu />
        </Hidden>
        <main className={classes.content}>
          {children}
        </main>
        <LogoutDialog
          onClose={this.onLogoutCancelClick}
          onLogout={this.logout}
          open={isLogoutConfOpen}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Base);
