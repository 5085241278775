import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Route } from 'react-router-dom';
import { actCallApiMe } from '../../redux/login/action';
import redirectLinks from '../../constants/redirectLinks';

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(actCallApiMe());
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginUser.isGetMeError === true
      || (this.props.loginUser.isGetMeError === false && !this.props.loginUser.user)) {
      this.reset();
    }
    if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  reset() {
    document.cookie = 'mjwt=; path=/; max-age=0';
    localStorage.clear();
    window.location.href = redirectLinks.LOGIN;
  }

  render() {
    return (
      <div>
        <Route>
          {this.props.children}
        </Route>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default connect(mapStateToProps)(Auth);
