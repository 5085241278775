import { ACTION_TYPE } from './action';

// #region Reducer
const eventList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    default:
      return state;
  }
};

export default eventList;
