// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_GRADE_LIST: 'CALL_API_GET_GRADE_LIST',
  CALL_API_GET_GRADE_LIST_SUCCESS: 'CALL_API_GET_GRADE_LIST_SUCCESS',
};
// #endregion

export const actCallApiGetGradeList = () => ({
  type: ACTION_TYPE.CALL_API_GET_GRADE_LIST,
});

export const actCallApiGetGradeListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_GRADE_LIST_SUCCESS,
  datas,
});
