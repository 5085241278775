import { call, put, takeLatest } from 'redux-saga/effects';
import getDatas from '../../../services/mypage/attendanceList.service';
import {
  ACTION_TYPE,
  actCallApiGetAttendanceListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetAttendanceList({ param }) {
  try {
    const datas = yield call(getDatas, param);
    yield put(actCallApiGetAttendanceListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallAttendanceList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ATTENDANCE_LIST, callApiGetAttendanceList);
}
