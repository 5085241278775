import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import store from '../store/store';
import '../public/css/style.css';
import Auth from './common/Auth';
import Login from './login/Login';
import redirectLinks from '../constants/redirectLinks';
import NotFound from './common/notFound/NotFound';
import SystemError from './common/systemError/SystemError';
import Base from './common/baseLayout/Base';
import ChangePassword from './mypage/changePassword/ChangePassword';
import GradeList from './mypage/gradeList/GradeList';
import EventList from './mypage/eventList/EventList';
import MemberQr from './mypage/memberQr/MemberQr';
import Home from './mypage/home/Home';
import AttendanceList from './mypage/attendanceList/AttendanceList';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#404040',
      main: '#111111',
      dark: '#0b0b0b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f33336',
      main: '#F10004',
      dark: '#a80002',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: '600',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={createHashHistory()}>
          <Switch>
            <Route exact path={redirectLinks.LOGIN} component={Login} />
            <Auth>
              <Base>
                <Switch>
                  <Route exact path={redirectLinks.HOME} component={Home} />
                  <Route exact path={redirectLinks.BASE} component={GradeList} />
                  <Route exact path={redirectLinks.EVENT} component={EventList} />
                  <Route exact path={redirectLinks.ATTENDANCE} component={AttendanceList} />
                  <Route exact path={redirectLinks.QR} component={MemberQr} />
                  <Route exact path={redirectLinks.CHANGE_PASSWORD} component={ChangePassword} />
                  <Route exact path={redirectLinks.ERROR} component={SystemError} />
                  <Route component={NotFound} />
                </Switch>
              </Base>
            </Auth>
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
