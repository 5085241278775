import { call, put, takeLatest } from 'redux-saga/effects';
import updatePassword from '../../../services/mypage/changePassword.service';
import {
  ACTION_TYPE,
  actCallApiChangePasswordSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiUpdatePassword(param) {
  try {
    yield call(updatePassword, param);
    yield put(actCallApiChangePasswordSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallChangePassword() {
  yield takeLatest(ACTION_TYPE.CALL_API_CHANGE_PASSWORD, callApiUpdatePassword);
}
