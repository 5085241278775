import { ACTION_TYPE } from './action';

// #region Reducer
const attendanceList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    default:
      return state;
  }
};

export default attendanceList;
