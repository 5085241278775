// #region Type
export const ACTION_TYPE = {
  CALL_API_SERVER_ERROR: 'CALL_API_SERVER_ERROR',
  CALL_API_RESET_SERVER_ERROR: 'CALL_API_RESET_SERVER_ERROR',
};
// #endregion

// #region action
export const actCallApiServerError = (error) => ({
  type: ACTION_TYPE.CALL_API_SERVER_ERROR,
  errorMessages: error.response.data.errorDetails,
});

export const actCallApiResetServerError = () => ({
  type: ACTION_TYPE.CALL_API_RESET_SERVER_ERROR,
});
// #endregion
