// #region Type
export const ACTION_TYPE = {
  CALL_API_LOGIN: 'CALL_API_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CALL_API_GET_ME: 'CALL_API_GET_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_ERROR: 'GET_ME_ERROR',
  LOGIN_DATA_RESET: 'LOGIN_DATA_RESET',
};
// #endregion

// #region action
export const actCallApiLogin = (userInfo) => ({
  type: ACTION_TYPE.CALL_API_LOGIN,
  userInfo,
});

export const actLoginError = (errorMessage) => ({
  type: ACTION_TYPE.LOGIN_ERROR,
  errorMessage,
});

export const actLoginSuccess = (token) => ({
  type: ACTION_TYPE.LOGIN_SUCCESS,
  token,
});

export const actLoginDataReset = () => ({
  type: ACTION_TYPE.LOGIN_DATA_RESET,
});

export const actCallApiMe = () => ({
  type: ACTION_TYPE.CALL_API_GET_ME,
});

export const actGetMeError = (errorMessage) => ({
  type: ACTION_TYPE.GET_ME_ERROR,
  errorMessage,
});

export const actGetMeSuccess = (data) => ({
  type: ACTION_TYPE.GET_ME_SUCCESS,
  data,
});
// #endregion
