import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { format } from 'date-fns';
import {
  withStyles, Container, Box,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import etuzanLogo from '../../../public/assets/etuzan.png';
import { actCallApiGetAttendanceList } from '../../../redux/mypage/attendanceList/action';
import MonthTitle from './MonthTitle';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '100px',
    },
  },
  cardRoot: {
    display: 'flex',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: 'auto',
    },
  },
  media: {
    flex: '0 0 auto',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    marginTop: '-30px',
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class AttendanceList extends React.Component {
  constructor(props) {
    super();
    const page = 0;
    const targetMonth = format(new Date(), 'yyyy/MM');
    props.dispatch(actCallApiGetAttendanceList({ page, records: 10, targetMonth }));
    this.state = {
      page,
      targetMonth,
    };
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onChangePage = (_, newPage) => {
    this.scrollToTop();
    const { targetMonth } = this.state;
    const page = parseInt(newPage, 10) - 1;
    this.setState({ page });
    this.props.dispatch(actCallApiGetAttendanceList({ page, records: 10, targetMonth }));
  }

  onMonthChange = (_, targetMonth) => {
    this.setState({ targetMonth });
    const { page } = this.state;
    this.props.dispatch(actCallApiGetAttendanceList({ page, records: 10, targetMonth }));
  }

  render() {
    const { classes } = this.props;
    const { page, targetMonth } = this.state;
    const datas = this.props.attendanceList.datas || {};

    return (
      <Container className={classes.root}>
        <Box>
          <MonthTitle targetMonth={targetMonth} onChange={this.onMonthChange} />
        </Box>

        {datas.content && datas.content.map((attend) => (
          <Box boxShadow={3} mt={3} p={3} bgcolor="#fff" className={classes.cardRoot} key={attend.id}>
            <Box mr={2} pr={3}>
              <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" mb={2}>{attend.menuName}</Box>
              <Box mb={2}>
                <Box fontWeight="fontWeightBold">出席日時</Box>
                <Box>{`${attend.attendedDate} ${attend.attendedTime}～`}</Box>
              </Box>
              <Box mb={2}>
                <Box fontWeight="fontWeightBold">練習場</Box>
                <Box>{attend.roomName}</Box>
              </Box>
              <Box mb={2}>
                <Box fontWeight="fontWeightBold">担当指導員</Box>
                <Box>{attend.teacherName}</Box>
              </Box>
            </Box>
            <Box textAlign={{ xs: 'right' }}>
              <img src={etuzanLogo} alt="ロゴ" />
            </Box>
          </Box>
        ))}
        <Box display={!datas.content || datas.content.length <= 0 ? 'none' : 'flex'} justifyContent="center" mt={3} bgcolor="#fff" p={2} boxShadow={1}>
          <Pagination
            page={page + 1}
            count={datas.totalPages}
            color="primary"
            onChange={this.onChangePage}
          />
        </Box>
        <Box display={datas.content && datas.content.length > 0 ? 'none' : ''} textAlign="center" mt={3} bgcolor="#fff" p={2} boxShadow={1} fontWeight="fontWeightBold">
          出席履歴はありません。
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ attendanceList: state.attendanceList });

export default withStyles(styles)(connect(mapStateToProps)(AttendanceList));
