import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { format } from 'date-fns';
import {
  withStyles, Container, Box,
} from '@material-ui/core';
import etuzanLogo from '../../../public/assets/etuzan.png';
import { actCallApiGetGradeList } from '../../../redux/mypage/gradeList/action';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '100px',
    },
  },
  cardRoot: {
    display: 'flex',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: 'auto',
    },
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

class GradeList extends React.Component {
  constructor(props) {
    super(props);
    props.dispatch(actCallApiGetGradeList());
  }

  render() {
    const { classes } = this.props;
    const grades = this.props.gradeList.datas || [];

    return (
      <Container className={classes.root}>
        {grades.map((grade, index) => (
          <Box boxShadow={3} mt={3} p={3} bgcolor="#fff" className={classes.cardRoot} key={grade.id}>
            <Box fontSize={index === 0 ? 'h2.fontSize' : 'h5.fontSize'} fontWeight="fontWeightBold" mr={2} className={classes.textCenter}>
              {grade.grade}
            </Box>
            <Box mr={2} className={classes.textCenter}>
              <Box display="flex" fontSize={17}>
                <Box mr={1} display={grade.acquireNumber ? '' : 'none'}>{grade.acquireNumber}</Box>
                <Box>{grade.acquireSection}</Box>
              </Box>
              <Box fontSize={17}>
                {format(new Date(grade.acquiredOn), 'yyyy年MM月dd日')}
              </Box>
            </Box>
            <Box textAlign={{ xs: 'right' }}>
              <img src={etuzanLogo} alt="ロゴ" />
            </Box>
          </Box>
        ))}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ gradeList: state.gradeList });

export default withStyles(styles)(connect(mapStateToProps)(GradeList));
