import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * Function use for call api login
 *
 * @param {object} userInfo
 */
export function login(userInfo) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER.LOGIN, userInfo)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getMe() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER.ME)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
