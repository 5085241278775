// #region Type
export const ACTION_TYPE = {
  RESET_CHANGE_PASSWORD_FLG: 'RESET_CHANGE_PASSWORD_FLG',
  CALL_API_CHANGE_PASSWORD: 'CALL_API_CHANGE_PASSWORD', // パスワード変更
  CALL_API_CHANGE_PASSWORD_SUCCESS: 'CALL_API_CHANGE_PASSWORD_SUCCESS',
};
// #endregion

export const actCallApiChangePassword = (password) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD,
  password,
});

export const actCallApiChangePasswordSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD_SUCCESS,
});

export const actResetChangePasswordFlg = () => ({
  type: ACTION_TYPE.RESET_CHANGE_PASSWORD_FLG,
});
